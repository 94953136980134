import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue')
  }]
},
{
  path: '/about',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'about-page',
    component: () => import(/* webpackChunkName: "about-page" */ '@/pages/About.vue')
  }]
},
{
  path: '/contact',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'contact-page',
    component: () => import(/* webpackChunkName: "contact-page" */ '@/pages/Contact.vue')
  }]
},
{
  path: '/terms',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'terms-page',
    component: () => import(/* webpackChunkName: "terms-page" */ '@/pages/Terms.vue')
  }]
},
{
  path: '/plans',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'plans-page',
    component: () => import(/* webpackChunkName: "terms-page" */ '@/pages/Plans.vue')
  }]
},
{
  path: '/privacy',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'privacy-page',
    component: () => import(/* webpackChunkName: "privacy-page" */ '@/pages/Privacy.vue')
  }]
},
{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
