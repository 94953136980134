import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    plans: []
  },
  getters: {
    loading: (state) => state.loading,
    loaded: (state) => state.loaded,
    plans: (state) => state.plans
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    setLoaded(state, payload) {
      state.loaded = payload
    },
    setPlans(state, plans) {
      state.plans = plans
    }
  },
  actions: {
    async getPlans({ commit }) {
      try {
        commit('setLoading', true)
        const response = await Vue.prototype.$axios.get('/v1/plans')

        commit('setLoading', false)
        commit('setLoaded', true)
        commit('setPlans', response.data.data.plans)
      } catch (error) {
        commit('setPlans', [])
      }
    }
  }
}
